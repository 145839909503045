var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.entities,"server-items-length":_vm.entitiesCount,"items-per-page":_vm.itemsPerPage,"footer-props":{ 'itemsPerPageOptions': [50, 100, 200, -1] },"loading":_vm.loading},on:{"pagination":_vm.paginate},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('src\\views\\trips\\list.7453')))]),_c('v-spacer')],1)]},proxy:true},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.createdAt,'DD.MM.YYYY HH:mm'))+" ")]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.firstname)+" "+_vm._s(item.user.lastname)+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(_vm.TRIP_TYPE_TITLE[item.type]))+" ")]}},{key:"item.sorting",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"9"}},[_c('v-text-field',{staticClass:"pa-0 ma-0",attrs:{"hide-details":""},model:{value:(item.sorting),callback:function ($$v) {_vm.$set(item, "sorting", $$v)},expression:"item.sorting"}})],1),(_vm.user.adminAccess && _vm.user.adminAccess.trips >= _vm.ACCESS.WRITE)?_c('v-col',{staticClass:"pa-0 d-flex justify-center align-center",attrs:{"cols":"3"}},[_c('v-icon',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.setSorting(item._id, item.sorting)}}},[_vm._v("mdi-check")])],1):_vm._e()],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(_vm.TRIP_STATUS_TITLE[item.status]))+" "),(_vm.user.adminAccess && _vm.user.adminAccess.trips >= _vm.ACCESS.WRITE)?_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"primary","small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-pencil")])]}}],null,true)},[_c('v-list',_vm._l((_vm.TRIP_STATUS_TITLE),function(status,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.setStatus(item._id, index)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t(status)))])],1)}),1)],1):_vm._e()]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.edit(item._id)}}},[_vm._v("mdi-pencil")])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }